<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <img :src="(skin == 'dark' ? appLogoImage : appLogoImageDark)" alt="Minera Poderosa Logo" class="img-fluid">
                <h2 class="brand-text text-primary ml-1">
                    {{ appName }}
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Bienvenido(a) a Investing Capacitaciones! 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        ¡Inicie sesión con su cuenta y comience hacer uso de los diferentes módulos que ofrece la plataforma!
                    </b-card-text>

                    <!-- form -->
                    <validation-observer
                        ref="loginForm"
                        #default="{invalid}">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="login"
                        >
                            <!-- email -->
                            <b-form-group
                                label="Correo electrónico"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Contraseña</label>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="invalid"
                            >
                                INICIAR SESIÓN
                            </b-button>
                        </b-form>
                    </validation-observer>

                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>

    import useAppConfig from '@core/app-config/useAppConfig';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import VuexyLogo from '@core/layouts/components/Logo.vue';
    import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue';
    import useJwt from '@/auth/jwt/useJwt';
    import { required, email } from '@validations';
    import { togglePasswordVisibility } from '@core/mixins/ui/forms';
    import store from '@/store/index';
    import { getHomeRouteForLoggedInUser } from '@/auth/utils';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { $themeConfig } from '@themeConfig';

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BCardText,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                status: '',
                password: '',
                userEmail: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                required,
                email,
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
                    return this.sideImg;
                }
                return this.sideImg;
            },
        },
        methods: {
            login() {
                this.$refs.loginForm.validate().then(success => {
                    if (success) {
                        useJwt.login({
                            email: this.userEmail,
                            password: this.password,
                        })
                        .then(response => {
      
                            const { userData } = response.data;

                            useJwt.setToken(response.data.accessToken);
                            useJwt.setRefreshToken(response.data.refreshToken);
                            localStorage.setItem('userData', JSON.stringify(userData));
                            this.$ability.update(userData.ability);

                            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                                .then(() => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Bienvenido(a) ${userData.names} ${userData.surnames}`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: `Ha iniciado sesión correctamente como ${userData.roleAlias}. ¡Ahora puedes empezar a explorar la plataforma!`,
                                        }
                                    })
                                });
                        })
                        .catch(error => {
                            this.$refs.loginForm.setErrors(error.response.data.error);
                        });
                    }
                });
            },
        },
        setup() {
			// App Name
			const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app

			const { skin } = useAppConfig()

			return {
				skin,

				appName,
				appLogoImage,
				appLogoImageDark
			}
		}
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>

    .brand-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>